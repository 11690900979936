<template>
  <BaseContainer>
    <CandidateShow />
  </BaseContainer>
</template>

<script>
import CandidateShow from '@components/ClientAssessments/CandidateShow'

export default {
  components: {
    CandidateShow
  }
}
</script>
